import type { PropType, VNode } from 'vue';
import type { SwiperOptions } from 'swiper/types';
import type { AdditionalProduct, Recipe } from '@ruokaboksi/api-client';
import { storeToRefs } from 'pinia';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import placeholderImageUrl from '@/assets/images/placeholder-card-image.png';
import SanityPicture from '@/components/SanityPicture';
import 'swiper/css';
import 'swiper/css/navigation';

export default defineComponent({
  name: 'ProductSwiper',
  props: {
    selectedRecipes: {
      default: null,
      type: Array as PropType<Recipe[]>,
    },
    selectedAdditionalProducts: {
      default: null,
      type: Array as PropType<AdditionalProduct[]>,
    },
  },
  setup(props) {
    const productModal = useProductModalStore();
    const { isModalVisible } = storeToRefs(productModal);
    const selectedProduct = ref<AdditionalProduct | Recipe | null>(null);
    const selectedProducts = computed<Array<AdditionalProduct | Recipe>>(() => [
      ...props.selectedRecipes,
      ...props.selectedAdditionalProducts,
    ]);
    const swiperBreakpoints = computed<SwiperOptions['breakpoints']>(() => {
      const productCount =
        props.selectedRecipes.length + props.selectedAdditionalProducts.length;
      return {
        0: {
          slidesPerView: productCount > 3 ? 3.25 : 3,
        },
        768: {
          slidesPerView: productCount > 4 ? 4.3 : 4,
        },
        1024: {
          slidesPerView: productCount > 5 ? 5.4 : 5,
        },
      };
    });

    const openModal = ($el: HTMLDivElement): void | Error => {
      const productId = $el.dataset.productId;
      const id = `product-modal-${productId}`;
      selectedProduct.value =
        selectedProducts.value.find((p) => p.id === productId) || null;
      if (!selectedProduct.value) {
        throw new Error(`Unable to show details for product id ${productId}`);
      }
      productModal.open({ id, product: selectedProduct.value });
    };

    const openModalOnClick = (event: Event): void => {
      event.preventDefault();
      const $target = event.target as HTMLDivElement;
      openModal($target);
    };

    const openModalOnEnter = (event: KeyboardEvent): void => {
      if (isModalVisible.value || event.code !== 'Enter') return;
      event.preventDefault();
      const $target = event.target as HTMLDivElement;
      openModal($target);
    };

    return {
      isModalVisible,
      modules: [Navigation],
      openModalOnClick,
      openModalOnEnter,
      selectedProduct,
      swiperBreakpoints,
    };
  },
  render(): VNode | null {
    if (!this.selectedRecipes || !this.selectedAdditionalProducts) return null;
    return (
      <div class="product-swiper-wrapper">
        <div class="product-swiper">
          <Swiper
            breakpoints={this.swiperBreakpoints}
            modules={this.modules}
            navigation={true}
          >
            {this.selectedRecipes.length
              ? this.selectedRecipes.map((recipe, index) => (
                  <SwiperSlide key={index}>
                    <div
                      class="swiper-item recipe"
                      data-product-id={recipe.id}
                      onClick={this.openModalOnClick}
                      onKeydown={this.openModalOnEnter}
                      role="button"
                      tabindex="0"
                    >
                      <SanityPicture
                        alt={recipe.plateImage?.alt ?? recipe.title}
                        class="image-wrapper"
                        src={
                          recipe.plateImage?.sizes?.thumbnail ??
                          recipe.mainImage?.sizes?.thumbnail ??
                          placeholderImageUrl
                        }
                      />
                      <div class="index">{index + 1}</div>
                    </div>
                  </SwiperSlide>
                ))
              : null}
            {this.selectedAdditionalProducts.length
              ? this.selectedAdditionalProducts.map((product, index) => (
                  <SwiperSlide key={index}>
                    <div
                      class="swiper-item additional-product"
                      data-product-id={product.id}
                      onClick={this.openModalOnClick}
                      onKeydown={this.openModalOnEnter}
                      role="button"
                      tabindex="0"
                    >
                      <SanityPicture
                        alt={product.mainImage?.alt ?? ''}
                        class="image-wrapper"
                        src={
                          product.mainImage?.sizes?.thumbnail ??
                          product.plateImage?.sizes?.thumbnail ??
                          placeholderImageUrl
                        }
                      />
                      <div class="index">
                        {index + this.selectedRecipes.length + 1}
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
      </div>
    );
  },
});
