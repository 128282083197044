import type { VNode } from 'vue';
import { PortableText } from '@portabletext/vue';
import type { TipType } from '@/components/Tip';
import IconCampaign from '@/assets/icons/icon-campaign.svg?component';
import DeliveryWeekOverview from '@/components/DeliveryWeekOverview';
import LoadingEggs from '@/components/LoadingEggs';
import SelectedProducts from '@/components/SelectedProducts';
import SelectedWeekPauseToggle from '@/components/SelectedWeekPauseToggle';
import SelectedWeekStatus from '@/components/SelectedWeekStatus';
import Tip from '@/components/Tip';
import WeekSelector from '@/components/WeekSelector';
import LoyaltyProgramBanner from '@/components/LoyaltyProgramBanner';
import DeliveryWeekCalendar from '@/components/DeliveryWeekCalendar';
import DeliveryDayDropdown from '@/components/DeliveryDayDropdown';

const OffersModal = defineAsyncComponent(
  () => import('@/components/OfferModal')
);

export default defineNuxtComponent({
  name: 'IndexPage',
  setup() {
    definePageMeta({ layout: 'default' });
    const { t, localeProperties } = useI18n();
    useHead({ title: t('index_page.title') });
    const { campaign, hasCampaign, isLoadingCustomerCampaigns } =
      useCustomerCampaigns();
    const { isSubscriptionSuspended, isLoadingSubscriptions } =
      useCustomerSubscriptions();
    const { selectedDeliveryWeek, isLoadingDeliveryWeeks } = useDeliveryWeeks();
    const { getCustomerOffers } = useOffersApi();

    const { data: offer } = getCustomerOffers();

    const isLoyaltyProgramVisible = computed(
      () => localeProperties.value.loyaltyProgramName
    );
    /** Shows alternate message when the user is about to miss out on an offer. */
    const campaignTipTitle = computed<string>(() => {
      if (
        selectedDeliveryWeek.value?.editable &&
        selectedDeliveryWeek.value?.paused
      ) {
        return t('index_page.campaign_tip_paused');
      }
      return campaign.value?.title || '';
    });

    /** Type for the campaign tip. */
    const campaignTipType = computed<TipType>(() =>
      selectedDeliveryWeek.value?.paused ? 'caution' : 'success'
    );

    return {
      campaign,
      campaignTipTitle,
      campaignTipType,
      hasCampaign,
      isLoadingCustomerCampaigns,
      isLoadingDeliveryWeeks,
      isLoyaltyProgramVisible,
      isLoadingSubscriptions,
      isSubscriptionSuspended,
      offer,
    };
  },
  render(): VNode | null {
    if (this.isLoadingCustomerCampaigns || this.isLoadingSubscriptions) {
      return <LoadingEggs loadingTextKey={this.$t('index_page.loading')} />;
    } else if (this.isLoadingDeliveryWeeks) {
      return (
        <LoadingEggs loadingTextKey={this.$t('index_page.loading_products')} />
      );
    }
    return (
      <div class="lg:flex">
        <div class="lg:flex-1">
          <WeekSelector title={this.$t('index_page.week_selector_title')} />
          <SelectedWeekPauseToggle />
          {this.hasCampaign ? (
            <Tip
              class="md:max-w-9/12 xl:max-w-2/4 mx-auto mt-7"
              title={this.campaignTipTitle}
              type={this.campaignTipType}
              v-slots={{ icon: () => <IconCampaign class="icon" /> }}
            >
              {this.campaign?.description ? (
                <div class="lg:hidden">
                  <PortableText value={this.campaign.description} />
                </div>
              ) : null}
            </Tip>
          ) : (
            this.isLoyaltyProgramVisible && <LoyaltyProgramBanner />
          )}
          <SelectedWeekStatus />
          <DeliveryWeekOverview view="desktop" />
          <DeliveryDayDropdown />
          <SelectedProducts />
          <DeliveryWeekOverview view="mobile" />
          {this.offer?.content ? (
            <OffersModal
              content={this.offer.content}
              offerId={this.offer.id}
              isVisible
            />
          ) : null}
        </div>
        <div class="lg:border-l-1 hidden basis-1/3 max-lg:p-4 lg:ml-8 lg:block lg:border-l-[#DADADA] lg:pl-8">
          <header class="delivery-week-calendar-header">
            <h2 class="main-heading">{this.$t('index_page.heading')}</h2>
          </header>
          <DeliveryWeekCalendar />
        </div>
      </div>
    );
  },
});
