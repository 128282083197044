import type { VNode } from 'vue';
import { format, isPast, subDays } from 'date-fns';
import { NuxtLink } from '#components';

export default defineComponent({
  name: 'SelectedWeekStatus',
  setup() {
    const {
      isSelectedDeliveryWeekEditable,
      isSelectedDeliveryWeekPaused,
      selectedDeliveryWeek,
    } = useDeliveryWeeks();
    const { t } = useI18n();

    const statusString = computed<string | VNode>(() => {
      if (
        !selectedDeliveryWeek.value?.recipes?.length ||
        isSelectedDeliveryWeekPaused.value
      ) {
        return '';
      }
      const recipeCount = selectedDeliveryWeek.value.recipes.length;
      const additionalProductCount =
        selectedDeliveryWeek.value.additionalProducts.length;
      if (!selectedDeliveryWeek.value.editable) {
        // This delivery has already been created

        return (
          <>
            {`${
              isPast(selectedDeliveryWeek.value.deliveryDate)
                ? t('selected_week_status.contained_recipes', {
                    count: recipeCount,
                  })
                : t('selected_week_status.contains_recipes', {
                    count: recipeCount,
                  })
            }${
              additionalProductCount
                ? ` ${t('selected_week_status.additional_products_count', {
                    count: additionalProductCount,
                  })}`
                : ''
            }. `}
            <NuxtLink
              to={{
                name: 'more-deliveries-id',
                params: { id: selectedDeliveryWeek.value.deliveryId },
              }}
            >
              {t('selected_week_status.more_info')}
            </NuxtLink>
          </>
        );
      } else if (selectedDeliveryWeek.value.hasProductChange) {
        return `${t('selected_week_status.recipes_of_choice', {
          count: recipeCount,
        })}${
          additionalProductCount
            ? ` ${t('selected_week_status.additional_products_of_choice', {
                count: additionalProductCount,
              })}`
            : ''
        }. `;
      }
      return `${t(
        'selected_week_status.delivery_contains_preselected_recipes',
        {
          count: recipeCount,
        }
      )}`;
    });

    const editableString = computed<string>(() => {
      if (
        !selectedDeliveryWeek.value?.paymentDate ||
        !isSelectedDeliveryWeekEditable.value
      ) {
        return '';
      }
      const editableUntil = subDays(selectedDeliveryWeek.value.paymentDate, 1);
      const date = format(editableUntil, 'd.M.');
      return t('selected_week_status.edit_until', { editUntilDate: date });
    });

    const pausedString = computed<string>(() => {
      if (!selectedDeliveryWeek.value?.editable) {
        return '';
      }
      if (isSelectedDeliveryWeekPaused.value) {
        return `${t('selected_week_status.week_paused', {
          weekNumber: format(
            new Date(selectedDeliveryWeek.value.deliveryDate),
            'd.M.'
          ),
        })}`;
      }

      return '';
    });

    return {
      editableString,
      pausedString,
      statusString,
    };
  },
  render(): VNode {
    return (
      <p class="mx-auto my-6 max-w-3xl text-center font-medium md:my-12 lg:my-12 lg:text-lg">
        {this.pausedString && <span>{this.pausedString}</span>}
        <span>{this.statusString}</span>
        {this.editableString && <span> {this.editableString}</span>}
      </p>
    );
  },
});
