import type { PropType, VNode } from 'vue';

export default defineComponent({
  name: 'ToggleSwitch',
  props: {
    align: {
      default: 'left',
      type: String as PropType<'left' | 'right'>,
    },
    checked: {
      default: undefined,
      type: Boolean,
    },
    name: {
      default: undefined,
      type: String,
    },
    handleToggle: {
      default: undefined,
      type: Function as PropType<(event: Event) => void>,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    invertColor: {
      default: false,
      type: Boolean,
    },
    swapColor: {
      default: false,
      type: Boolean,
    },
  },
  render(): VNode {
    const classes = {
      'toggle-switch': true,
      'swap-color': this.swapColor,
    };
    return (
      <label class={classes} data-align={this.align}>
        <input
          class="input-hidden toggle-input"
          name={this.name}
          checked={this.checked}
          onChange={this.handleToggle}
          disabled={this.disabled}
          type="checkbox"
        />
        <span class={{ 'toggle-slider': true, inverted: this.invertColor }} />
        <span class="toggle-text">{this.$slots.default?.()}</span>
      </label>
    );
  },
});
