import type { VNode } from 'vue';
import type { AdditionalProduct, Recipe } from '@ruokaboksi/api-client';
import {
  calculateAdditionalProductPrice,
  makeStartOfDayUTC,
  Price,
  Week,
  ZERO_PRICE,
} from '@ruokaboksi/utils';
import { NuxtLink } from '#components';
import ProductCard from '@/components/ProductCard';
import ProductSwiper from './ProductSwiper';
import './SelectedProducts.css';

export default defineComponent({
  name: 'SelectedProducts',
  setup() {
    const {
      isLoadingDeliveryWeeks,
      isSelectedDeliveryWeekEditable,
      isSelectedDeliveryWeekPaused,
      selectedDeliveryWeek,
    } = useDeliveryWeeks();
    const { isLoadingWeekConfigurations, weekConfiguration } =
      useWeekConfigurations();
    const { subscription } = useCustomerSubscriptions();
    const { t } = useI18n();

    const { market } = useCurrentMarket();
    const isFinnishMarket = computed(() => market.value === 'FIN');

    const selectedRecipes = computed<Recipe[]>(() =>
      selectedDeliveryWeek.value?.recipes?.length &&
      weekConfiguration.value?.recipes?.length
        ? selectedDeliveryWeek.value.recipes.map(
            (recipe) =>
              weekConfiguration.value.recipes.find(
                (r) => r.id === recipe?.id
              ) ?? {
                ...fallbackRecipe,
                title: t(fallbackRecipe.title),
                id: recipe.id,
                taxRate: recipe.taxRate,
                price: recipe.premiumPrice,
                isPremium: recipe.isPremium,
              }
          )
        : []
    );

    const selectedAdditionalProducts = computed<AdditionalProduct[]>(() =>
      selectedDeliveryWeek.value?.additionalProducts?.length &&
      weekConfiguration.value?.additionalProducts?.length
        ? selectedDeliveryWeek.value.additionalProducts.map(
            (product) =>
              weekConfiguration.value.additionalProducts.find(
                (r) => r.id === product?.id
              ) ?? {
                ...fallbackAdditionalProduct,
                title: t(fallbackAdditionalProduct.title),
                id: product.id,
                price: product.price.netPrice,
                sku: product.id,
                taxRate: product.taxRate,
              }
          )
        : []
    );

    const priceForAdditionalProduct = (
      product?: AdditionalProduct | null
    ): Price => {
      if (!product || !subscription.value) {
        return ZERO_PRICE;
      }

      return calculateAdditionalProductPrice({
        additional: { ...product, frozenProductPrice: product.price ?? 0 },
        campaign: selectedDeliveryWeek.value?.activeCampaign ?? null,
        now: Week.fromPaymentDate(
          makeStartOfDayUTC(selectedDeliveryWeek.value?.paymentDate),
          subscription.value.defaultDeliverySlot
        ),
      });
    };

    const buttonTextRecipes = computed<string>(() =>
      isSelectedDeliveryWeekEditable.value
        ? `${t('selected_products.change_week')} ${
            selectedDeliveryWeek.value?.weekNumberString
          } ${t('selected_products.recipes')}`
        : `${t('selected_products.open')} ${
            selectedDeliveryWeek.value?.weekNumberString
          } ${t('selected_products.recipes')}`
    );

    const buttonTextAdditionalProducts = computed<string>(() => {
      if (!isSelectedDeliveryWeekEditable.value) {
        return `${t('selected_products.open')} ${
          selectedDeliveryWeek.value?.weekNumberString
        } ${t('selected_products.additional_products')}`;
      }

      if (selectedAdditionalProducts.value.length === 0) {
        return `${t(
          'selected_products.add_additional_products'
        )} ${selectedDeliveryWeek.value?.weekNumberString}`;
      }

      return `${t('selected_products.change')} ${
        selectedDeliveryWeek.value?.weekNumberString
      } ${t('selected_products.additional_products')}`;
    });

    return {
      buttonTextAdditionalProducts,
      buttonTextRecipes,
      isFinnishMarket,
      isLoadingDeliveryWeeks,
      isLoadingWeekConfigurations,
      isSelectedDeliveryWeekEditable,
      isSelectedDeliveryWeekPaused,
      priceForAdditionalProduct,
      selectedAdditionalProducts,
      selectedDeliveryWeek,
      selectedRecipes,
    };
  },
  render(): VNode | null {
    return (
      <div class="selected-products">
        <div class="lg:hidden">
          {this.selectedRecipes.length ||
          this.selectedAdditionalProducts.length ? (
            <>
              {this.selectedRecipes.length ? (
                <h2 class="sr-only">{this.$t('selected_products.selected')}</h2>
              ) : null}
              <ProductSwiper
                class={{ paused: this.isSelectedDeliveryWeekPaused }}
                selectedRecipes={this.selectedRecipes}
                selectedAdditionalProducts={this.selectedAdditionalProducts}
              />
            </>
          ) : this.isLoadingDeliveryWeeks ||
            this.isLoadingWeekConfigurations ? (
            <div class="no-data">
              <p class="motion-safe:animate-bounce">
                {this.$t('global.loading')}
              </p>
            </div>
          ) : (
            <div class="no-data">
              <p>{this.$t('selected_products.no_selections')}</p>
            </div>
          )}

          <div class="product-list">
            {this.selectedRecipes.map((recipe, index) => (
              <div class="product-list-item recipe">
                <div class="index">{index + 1}</div>
                <div class="title">{recipe?.title}</div>
              </div>
            ))}
            <div class="relative my-7 text-center">
              <NuxtLink class="button button-primary" to={keepQuery('recipes')}>
                {this.buttonTextRecipes}
              </NuxtLink>
            </div>

            {this.isFinnishMarket && (
              <div>
                <h2 class="heading">
                  {this.$t('selected_products.additional_products_heading')}
                </h2>

                {this.selectedAdditionalProducts.map((product, index) => (
                  <div class="product-list-item additional-product">
                    <div class="index">
                      {index + this.selectedRecipes.length + 1}
                    </div>
                    <div class="title">{product?.title}</div>
                  </div>
                ))}
                <div class="my-4 text-center">
                  <NuxtLink
                    class="button button-primary"
                    to={keepQuery('additional-products')}
                  >
                    {this.buttonTextAdditionalProducts}
                  </NuxtLink>
                </div>
              </div>
            )}
          </div>
        </div>
        <div class="max-lg:hidden">
          <h2
            class={{
              'product-header': true,
              paused: this.isSelectedDeliveryWeekPaused,
            }}
          >
            {this.$t('selected_products.selected_recipes')}
          </h2>
          {this.selectedRecipes.length ? (
            <div
              class="product-cards"
              data-card-count={this.selectedRecipes.length}
            >
              {this.selectedRecipes.map((recipe, index) => (
                <ProductCard
                  data-type="recipe"
                  editable={false}
                  hideActions={true}
                  index={index + 1}
                  key={index}
                  modalId={`product-modal-${recipe?.id}`}
                  paused={this.isSelectedDeliveryWeekPaused}
                  product={recipe}
                  selected={true}
                  lazyLoadImages={true}
                />
              ))}
            </div>
          ) : this.isLoadingDeliveryWeeks ||
            this.isLoadingWeekConfigurations ? (
            <div class="no-data">
              <p class="motion-safe:animate-bounce ">
                {this.$t('global.save')}
              </p>
            </div>
          ) : (
            <div class="no-data">
              <p>{this.$t('selected_products.no_selections')}</p>
            </div>
          )}
          <div class="my-12 text-center">
            <NuxtLink class="button button-primary" to={keepQuery('recipes')}>
              {this.buttonTextRecipes}
            </NuxtLink>
          </div>
          <h2
            class={{
              'product-header additional-product-header': true,
              paused: this.isSelectedDeliveryWeekPaused,
            }}
          >
            {this.$t('selected_products.selected_additional_products')}
          </h2>

          <div
            class="product-cards additional-product-cards"
            data-card-count={this.selectedAdditionalProducts.length}
          >
            {this.selectedAdditionalProducts.map((product, index) => (
              <ProductCard
                data-type="additional-product"
                discount={this.priceForAdditionalProduct(product).discount}
                editable={false}
                hideActions={true}
                index={index + 1}
                key={index}
                modalId={`product-modal-${product?.id}`}
                paused={this.isSelectedDeliveryWeekPaused}
                product={product}
                selected={true}
                lazyLoadImages={true}
              />
            ))}
          </div>

          <div class="my-12 text-center">
            <NuxtLink
              class="button button-primary"
              to={keepQuery('additional-products')}
            >
              {this.buttonTextAdditionalProducts}
            </NuxtLink>
          </div>
        </div>
      </div>
    );
  },
});
