import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { format } from 'date-fns';
import { KEY_DELIVERY_WEEKS } from './useDeliveriesApi';

export default function useOffersApi() {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { subscriptionId } = useCustomerSubscriptions();

  const getCustomerOffers = () => {
    const queryFn = async () => {
      const response = await apiClient.offers.retrieve({
        customerId: '_me',
      });

      return response;
    };
    const queryKey = ['customerOffers'];
    return useQuery({ queryKey, queryFn });
  };

  const activateOffer = async (date: string) => {
    const response = await apiClient.offers.activate({
      data: {
        date: format(date, 'yyyy-MM-dd'),
      },
      customerId: '_me',
    });

    await queryClient.invalidateQueries({
      queryKey: ['campaignLinkBySubscriptionId', subscriptionId.value],
    });
    await queryClient.invalidateQueries({
      queryKey: [KEY_DELIVERY_WEEKS],
    });

    return response;
  };

  return {
    getCustomerOffers,
    activateOffer,
  };
}
